<template>
  <Container theme="dark" :padding="false">
    <MsgItem v-for="(item, j) in list" :key="item.title" :title="item.title" :imageUrl="item.icon"
      :borderBottom="j < list.length - 1" :path="item.path" @goToRoute="goToRoute">
      <template v-if="item.title === '个人消息'">
        <div slot="subTitle" class="icon_badge_lg" v-if="formatNum > 0 && formatNum !== undefined">
          <div>{{ formatNum }}</div>
        </div>
      </template>
    </MsgItem>
  </Container>
</template>

<script>
import { mapState } from "vuex";
import Container from "../components/Container";
import MsgItem from "../components/MsgItem.vue";
import system from "@/assets/images/public/ic_information_system.png";
import personal from "@/assets/images/public/ic_information_personal.png";
export default {
  name: 'Msg',
  components: {
    Container,
    MsgItem,
  },
  data() {
    return {
      isLoading: false,
      list: [
        {
          icon: system,
          title: "系统消息",
          path: "/my/msg/system",
        },
        {
          icon: personal,
          title: "个人消息",
          path: "/my/msg/personal",
        },
      ],
    };
  },
  computed: {
    ...mapState("msg", ["num"]),
    formatNum() {
      return this.num < 100 ? this.num : 99 + "+";
    },
  },
  methods: {
    goToRoute(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>
<style lang="scss" scoped>
.icon_badge_lg {
  display: flex;
  min-width: 1.2rem;
  height: 1.2rem;
  background-color: #e44747;
  box-shadow: 1px 1px 2px #863333;
  border-radius: 1.2rem;
  color: #fff;
  right: 3.5rem;
  padding: 0.25rem 0.35rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-right: 1rem;

  div {
    font-size: 0.75rem;
  }
}
</style>
